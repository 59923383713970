// src/App.js
import React, { useState } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import GrammarChecker from './components/GrammarChecker';
import TranslateText from './components/TranslateText';
import IntroductionPage from './components/IntroductionPage';
import TranslateFile from './components/TranslateFile';
import AssistantForm from './components/AssistantForm'; 
import SearchWord from './components/SearchWord'; 
import VocabularyList from './components/VocabularyList'; 
import LearningBoard from './components/LearningBoard';
import VocabularyReview from './components/VocabularyReview';




const App = () => {
  const [selectedSection, setSelectedSection] = useState(null); // Trạng thái cho phần được chọn
  const [selectedPromptText, setSelectedPromptText] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);

  // Xử lý khi chọn một mục từ Sidebar
  const handleSelectSection = (section, item) => {
    setSelectedSection(section);
    if (section === 'GrammarCheck') {
      setSelectedPromptText(item.promptText);
      setSelectedTitle(item.label);
    } else {
      setSelectedPromptText(null);
      setSelectedTitle(null);
    }
  };

  // Hàm để hiển thị GrammarChecker khi nhấn Check Grammar
  const handleCheckGrammar = () => {
      handleSelectSection('GrammarCheck', { label: "Grammar Check", promptText: "" });
    };

  const handleAssistantClick = () => {
    setSelectedSection('Assistant');
  };


  // Hàm để hiển thị GrammarChecker khi nhấn Explore Grammar Check
  const handleExploreGrammar = () => {
    handleSelectSection('GrammarCheck', { label: "Grammar check", promptText: "" });
  };

  // Hàm để hiển thị TranslateText khi nhấn Try Translation
  const handleExploreTranslate = () => {
    handleSelectSection('TranslateText');
  };

  return (
    <div className="app">
      <Header onCheckClick={handleCheckGrammar} onAssistantClick={handleAssistantClick} />
      <Sidebar onSelectSection={handleSelectSection} />
      <main className="content">
      {!selectedSection && (
          <IntroductionPage
            onExploreGrammar={handleExploreGrammar}
            onExploreTranslate={handleExploreTranslate}
          />
        )}
        {selectedSection === 'GrammarCheck' && (
          <GrammarChecker initialPrompt={selectedPromptText} title={selectedTitle} />
        )}
        {selectedSection === 'TranslateText' && <TranslateText />}
        {selectedSection === 'TranslateFile' && <TranslateFile />} 
        {selectedSection === 'Assistant' && <AssistantForm />} 

        {selectedSection === 'SearchWord' && <SearchWord />}
        {selectedSection === 'VocabularyList' && <VocabularyList />}
        {selectedSection === 'LearningBoard' && <LearningBoard />} 
        {selectedSection === 'VocabularyReview' && <VocabularyReview />}
      </main>
    </div>
  );
};

export default App;