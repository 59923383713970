import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import ReactMarkdown from 'react-markdown';
import '../styles/LearningBoard.css';
import LoginPopup from './LoginPopup';

const LearningBoard = () => {
  const [data, setData] = useState({
    newWord: [],
    learning: [],
    hardWord: [],
    remembered: [],
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  // Kiểm tra trạng thái đăng nhập
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLoginSuccess = (email) => {
    setIsLoggedIn(true);
    setShowLoginPopup(false);
  };
  
  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const fetchWords = async () => {
    if (!isLoggedIn) return; // Không tải dữ liệu nếu chưa đăng nhập

    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found, please login again.');

      const res = await axios.get(`${API_URL}/api/words`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const wordsWithStatus = res.data.map((word) => ({
        ...word,
        status: word.status || 'NewWord',
      }));

      setData({
        newWord: wordsWithStatus.filter((word) => word.status === 'NewWord'),
        learning: wordsWithStatus.filter((word) => word.status === 'Learning'),
        hardWord: wordsWithStatus.filter((word) => word.status === 'HardWord'),
        remembered: wordsWithStatus.filter((word) => word.status === 'Remembered'),
      });
    } catch (err) {
      console.error('Error fetching words:', err);
      setError('Failed to load vocabulary data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWords();
  }, [isLoggedIn]);

  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      console.warn('No destination found. Drag cancelled.');
      return;
    }

    const sourceList = Array.from(data[source.droppableId]);
    const destList = Array.from(data[destination.droppableId]);
    const [movedItem] = sourceList.splice(source.index, 1);

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found, please login again.');

      const response = await axios.put(
        `${API_URL}/api/words/${movedItem._id}/status`,
        { status: destination.droppableId.charAt(0).toUpperCase() + destination.droppableId.slice(1) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      destList.splice(destination.index, 0, {
        ...movedItem,
        status: destination.droppableId,
      });

      setData((prevState) => ({
        ...prevState,
        [source.droppableId]: sourceList,
        [destination.droppableId]: destList,
      }));
    } catch (error) {
      console.error('Error updating word status:', error);
      setError('Failed to update word status.');

      setData((prevState) => ({
        ...prevState,
        [source.droppableId]: [...sourceList, movedItem],
        [destination.droppableId]: destList.filter((item) => item._id !== movedItem._id),
      }));
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div className="learning-board-container">
      <h2>Learning Management Board</h2>
      {
    !isLoggedIn ? (
      <div className="user-guide">
        <div className="guide-header">
          <h2>📚 Welcome to Your Learning Board</h2>
          <p>Manage your vocabulary effectively!</p>
        </div>
        <div className="guide-content">
          <h3>How to Get Started</h3>
          <ul>
            <li>🔑 <strong>Login:</strong> Sign in to your account to unlock this feature.</li>
            <li>🔍 <strong>Search:</strong> Use the "Search Word" feature to find and save words to your vocabulary.</li>
            <li>🚀 <strong>Organize:</strong> Drag and drop words into categories to track your progress.</li>
          </ul>
          <p>✨ Start learning and take control of your vocabulary journey!</p>
        </div>
        <div className="guide-footer">
          <button className="login-btn" onClick={() => setShowLoginPopup(true)}>
          Login Now
          </button>
        </div>
      </div>
    )  : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="board-columns">
            {Object.keys(data).map((key) => (
              <Droppable droppableId={key} key={key}>
                {(provided) => (
                  <div
                    className="column"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="column-header">
                      <h3>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</h3>
                    </div>
                    <div className="column-content">
                      {data[key]?.length > 0 ? (
                        data[key].map((word, index) => (
                          <Draggable
                            key={word._id}
                            draggableId={word._id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="word-item"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => setSelectedWord(word)}
                              >
                                <span className="word-text">{word.word}</span>
                              </div>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <p className="empty-column">No words available</p>
                      )}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      )}
      {showLoginPopup && (
      <LoginPopup
        onClose={() => setShowLoginPopup(false)}
        isLoggedIn={isLoggedIn}
        onLoginSuccess={handleLoginSuccess}
        onLogout={handleLogout}
      />
    )}
          {selectedWord && (
        <div className="popup-overlay" onClick={() => setSelectedWord(null)}>
          <div className="popup-container" onClick={(e) => e.stopPropagation()}>
            <div className="popup-header">
              <h3>{selectedWord.word || 'Chi tiết từ vựng'}</h3>
            </div>
            <div className="popup-content">
              {selectedWord.meaning && (
                <>
                  <h4>Meaning</h4>
                  <ReactMarkdown>{String(selectedWord.meaning)}</ReactMarkdown>
                </>
              )}
              {selectedWord.examples && (
                <>
                  <h4>Examples</h4>
                  <ReactMarkdown>{String(selectedWord.examples)}</ReactMarkdown>
                </>
              )}
              {selectedWord.usage && (
                <>
                  <h4>Usage</h4>
                  <ReactMarkdown>{String(selectedWord.usage)}</ReactMarkdown>
                </>
              )}
              {selectedWord.tips && (
                <>
                  <h4>Tips</h4>
                  <ReactMarkdown>{String(selectedWord.tips)}</ReactMarkdown>
                </>
              )}
            </div>
            <div className="popup-footer">
              <button className="close-btn" onClick={() => setSelectedWord(null)}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LearningBoard;