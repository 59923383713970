import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Header.css';
import LoginPopup from './LoginPopup';

const Header = ({ onCheckClick, onAssistantClick }) => {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false); // Trạng thái hiển thị popup confirm logout
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3100';


  // Hàm kiểm tra trạng thái token
  const validateToken = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setIsLoggedIn(false);
      setUserName('');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/auth/validate`, { token });
      if (response.data.valid) {
        setIsLoggedIn(true);
        setUserName(localStorage.getItem('userName') || 'User');
      } else {
        setIsLoggedIn(false);
        setUserName('');
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
      }
    } catch (error) {
      console.error('Token validation failed:', error.response?.data?.message || error.message);
      setIsLoggedIn(false);
      setUserName('');
      localStorage.removeItem('token');
      localStorage.removeItem('userName');
    }
  };

  // Kiểm tra trạng thái khi component mount hoặc localStorage thay đổi
  useEffect(() => {
    validateToken();

    const handleStorageChange = () => {
      validateToken();
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    setIsLoggedIn(false);
    setUserName('');
    alert('Bạn đã đăng xuất thành công!');
  };

  return (
    <header className="header">
      <div className="logo" onClick={() => window.location.reload()} style={{ cursor: 'pointer' }}>
        <div className="logo-icon">
          <img src={`${process.env.PUBLIC_URL}/main_icon.png`} alt="JapNative Icon" className="logo-image" />
        </div>
        <span className="logo-text">JA語Native</span>
      </div>
      <div className="header-buttons">
        <button className="assistant-button" onClick={onAssistantClick}>
          Assistant
        </button>
        <button className="check-button" onClick={onCheckClick}>
          Check Grammar
        </button>
        <div className="login-container">
          {/* Hiển thị username hoặc nút Login */}
          {isLoggedIn ? (
            <button
              className="login-button"
              onClick={() => setShowLogoutConfirm(true)} // Hiển thị popup confirm logout
            >
              {userName}
            </button>
          ) : (
            <button
              className="login-button"
              onClick={() => setShowLoginPopup(true)} // Hiển thị popup login
            >
              Login
            </button>
          )}
        </div>
      </div>

      {/* Hiển thị popup Login */}
      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          isLoggedIn={isLoggedIn}
          onLoginSuccess={(name) => {
            setIsLoggedIn(true);
            setUserName(name);
            localStorage.setItem('userName', name);
            setShowLoginPopup(false);
          }}
        />
      )}

      {/* Hiển thị popup Confirm Logout */}
      {showLogoutConfirm && (
        <div className="popup-overlay" onClick={() => setShowLogoutConfirm(false)}>
          <div className="popup-container" onClick={(e) => e.stopPropagation()}>
            <h3>またね👋</h3>
            <p>ログアウトでよろしいでしょうか?</p>
            <div className="popup-footer">
              <button
                className="popup-logout-btn"
                onClick={() => {
                  handleLogout();
                  setShowLogoutConfirm(false);
                }}
              >
                ログアウト
              </button>
            </div>
          </div>
        </div>
      )}
    </header> 
  );
};

export default Header;