// src/components/Sidebar.js
import React, { useEffect, useState } from 'react';
import '../styles/Sidebar.css';
import { API_URL } from '../config';
import { BiAnalyse } from 'react-icons/bi';
import { FaChevronDown, FaChevronRight, FaAtlas, FaFileAlt, FaSearch, FaBook, FaChalkboardTeacher } from 'react-icons/fa';

const Sidebar = ({ onSelectSection }) => {
  const [items, setItems] = useState([]);
  const [expandCorrectText, setExpandCorrectText] = useState(false);
  const [expandTranslateText, setExpandTranslateText] = useState(false);
  const [expandVocabulary, setExpandVocabulary] = useState(false); // State cho tab "Từ cũ"
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/api/prompts`)
      .then(response => response.json())
      .then(data => {
        console.log("Fetched data:", data);
        setItems(data);
      })
      .catch(error => console.error("Error fetching prompts:", error));
  }, []);

  const handleSelect = (section, item) => {
    onSelectSection(section, item); // Gọi hàm từ props
    setSelectedId(item ? item._id : null); // Đánh dấu ID của mục được chọn
  };

  return (
    <aside className="sidebar">
      <ul>
        {/* Từ cũ của người từ mới của ta */}
        <li onClick={() => setExpandVocabulary(!expandVocabulary)} className="section-title">
          Old to New Words
          {expandVocabulary ? <FaChevronDown className="arrow-icon" /> : <FaChevronRight className="arrow-icon" />}
        </li>
        {expandVocabulary && (
          <ul className="sub-menu">
            {/* Search Word */}
            <li
              onClick={() => handleSelect('SearchWord', { _id: 'search-word', label: 'Search Word' })}
              className={`sub-menu-item ${selectedId === 'search-word' ? 'selected' : ''}`}
            >
              <FaSearch className="icon" />
              Search Word
            </li>
            {/* Vocabulary List */}
            <li
              onClick={() => handleSelect('VocabularyList', { _id: 'vocabulary-list', label: 'Vocabulary List' })}
              className={`sub-menu-item ${selectedId === 'vocabulary-list' ? 'selected' : ''}`}
            >
              <FaBook className="icon" />
              Vocabulary List
            </li>
            {/* Learning Board */}
            <li
              onClick={() => handleSelect('LearningBoard', { _id: 'learning-board', label: 'Learning Board' })}
              className={`sub-menu-item ${selectedId === 'learning-board' ? 'selected' : ''}`}
            >
              <FaChalkboardTeacher className="icon" />
              Learning Board
            </li>
            {/* Vocabulary Review */}
            <li
              onClick={() => handleSelect('VocabularyReview', { _id: 'vocabulary-review', label: 'Vocabulary Review' })}
              className={`sub-menu-item ${selectedId === 'vocabulary-review' ? 'selected' : ''}`}
            >
              <FaBook className="icon" />
              Vocabulary Review
            </li>
          </ul>
        )}

        {/* Correct Text Section */}
        <li onClick={() => setExpandCorrectText(!expandCorrectText)} className="section-title">
          Correct Text
          {expandCorrectText ? <FaChevronDown className="arrow-icon" /> : <FaChevronRight className="arrow-icon" />}
        </li>
        {expandCorrectText && (
          <ul className="sub-menu">
            {items.map((item) => (
              <li
                key={item._id}
                onClick={() => handleSelect('GrammarCheck', item)}
                className={`sub-menu-item ${selectedId === item._id ? 'selected' : ''}`}
              >
                <BiAnalyse className="icon" />
                {item.label}
              </li>
            ))}
          </ul>
        )}

        {/* Translate Section */}
        <li onClick={() => setExpandTranslateText(!expandTranslateText)} className="section-title">
          Translate
          {expandTranslateText ? <FaChevronDown className="arrow-icon" /> : <FaChevronRight className="arrow-icon" />}
        </li>
        {expandTranslateText && (
          <ul className="sub-menu">
            <li
              onClick={() => handleSelect('TranslateText', { _id: 'translate-text', label: 'Translate Text' })}
              className={`sub-menu-item ${selectedId === 'translate-text' ? 'selected' : ''}`}
            >
              <FaAtlas className="icon" />
              Translate Text
            </li>
            <li
              onClick={() =>
                handleSelect('TranslateFile', { _id: 'translate-file', label: 'Translate File' })
              }
              className={`sub-menu-item ${selectedId === 'translate-file' ? 'selected' : ''}`}
            >
              <FaFileAlt className="icon" />
              Translate File
            </li>
          </ul>
        )}

      </ul>
    </aside>
  );
};

export default Sidebar;