import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { API_URL } from '../config';
import '../styles/VocabularyReview.css';

const VocabularyReview = () => {
  const [words, setWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    const fetchWords = async () => {
      try {
        const token = localStorage.getItem('token');
        const res = await axios.get(`${API_URL}/api/words`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        console.log(res.data);
        setWords(res.data);
      } catch (error) {
        console.error('Error fetching words for review:', error);
      }
    };
    fetchWords();
  }, []);

  const handleNextWord = () => {
    setIsFlipped(false);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
  };

  const handlePreviousWord = () => {
    setIsFlipped(false);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + words.length) % words.length);
  };

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="review-page">
      <div className="review-container">
        {words.length > 0 ? (
          <div className="review-content">
            <button onClick={handlePreviousWord} className="btn-back">
              ← Back
            </button>
            <div
              className={`review-card ${isFlipped ? 'flipped' : ''}`}
              onClick={handleCardClick}
            >
              <div className="card-front">
                <h3>
                  {words[currentIndex]?.word} 
                </h3>
                <p>({currentIndex + 1}/{words.length})</p>
                <p className="hint">Try to guess the meaning of this word!</p>
              </div>
              <div className="card-back">
                <div className="review-detail">
                  <h4>Meaning:</h4>
                  <ReactMarkdown>{words[currentIndex]?.meaning || 'N/A'}</ReactMarkdown>
                </div>
                <div className="review-detail">
                  <h4>Example:</h4>
                  <ReactMarkdown>{words[currentIndex]?.examples?.[0] || 'N/A'}</ReactMarkdown>
                </div>
                <div className="review-detail">
                  <h4>Tips:</h4>
                  <ReactMarkdown>{words[currentIndex]?.tips || 'N/A'}</ReactMarkdown>
                </div>
              </div>
            </div>
            <button onClick={handleNextWord} className="btn-next">
              Next →
            </button>
          </div>
        ) : (
          <div className="no-words-container">
            <p className="no-words-message">
              No words available for review. Add some words to your vocabulary list first.
            </p>
            <p className="user-guide">
              <strong>To review vocabulary:</strong>
              <ol>
                <li>Add words to your vocabulary list.</li>
                <li>Return here to review and test your knowledge!</li>
              </ol>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VocabularyReview;